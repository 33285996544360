.body-portafolio{
  background-color: black;
  padding: 1% 0;
  text-align: center;
  width: 100%;
}

.header-portafolio{
  background-color: black;
  padding: 1% 0;
  text-align: center;
  width: 100%;
}

.portafolio-ficha-edificios {
  color: #2CE67C;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 5vw;
}
.logoportafolio {
  width: 8%;
}
  