.categoria {
    text-align: center;
  
    .botones {
      .link {
        $size: 180px;
  
        align-items: center;
        background-color: whitesmoke;
        border-radius: 10px;
        border: 1px solid #ccc;
        color: darkslategrey;
        display: flex;
        flex-direction: column;
        gap: 15px;
        height: $size;
        justify-content: center;
        margin: 2rem auto 0;
        padding: 0;
        text-decoration: none;
        transition: 0.3s;
        width: $size;
  
        .icon {
          transition: 0.3s;
          color: inherit;
        }
  
        &:hover {
          background-color: #e3011b;
          color: white;
  
          svg {
            color: white !important;
          }
        }
      }
    }
  }
  