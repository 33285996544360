/* styles.scss */

/* Clase específica para centrar las tablas */
.table-centered {
    margin: 0 auto; /* Centramos la tabla */
    text-align: center; /* Centramos el texto en las celdas */
  }
  
  .table-centered th,
  .table-centered td {
    text-align: center; /* Asegura que el texto de todas las celdas esté centrado */
  }
  
  .table-hover tbody tr:hover {
    background-color: #f1f1f1; /* Efecto hover para filas */
  }
  
  .table-sm th,
  .table-sm td {
    padding: 0.5rem; /* Ajusta el relleno de las celdas para una tabla más compacta */
  }
  
  .d-flex {
    display: flex;
    justify-content: flex-end; /* Alinea selectores al final de la celda */
  }
  