.Login {
  .botonLogin {
    -moz-user-select: none;
    -ms-touch-action: manipulation;
    -ms-user-select: none;
    -webkit-user-select: none;
    background-color: black;
    background-image: none;
    border-radius: 4px;
    border: 1px solid transparent;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 0;
    margin-right: 1px;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 150px;
  }

  .invalid-feedback {
    color: red; // Estilo para el mensaje de error
    margin-top: 0.25rem; // Ajusta el margen superior según sea necesario
  }
}
