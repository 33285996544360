.horason {
    background-color: rgb(36, 36, 36);
    border-radius: 10px;
    border: .5px darkslategray solid;
    color: white;
}
.titulohorason {
    color: white;
    font-size: 35px;
    margin-top: 1rem;
    text-align: center;
}
.subtitulo {
    color: white;
    font-size: 25px;
    margin-top: 1rem;
    text-align: center;
}
