.Loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  &.full {
    min-height: 100vh;
  }

  @keyframes animloader {
    0% {
      height: 100%;
    }
    100% {
      height: 0%;
    }
  }

  .animation {
    font-size: 48px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    display: inline-block;
    color: #ff3d00;
    letter-spacing: 2px;
    position: relative;
    box-sizing: border-box;

    &:after {
      content: attr(data-title);
      position: absolute;
      left: 0;
      top: 0;
      color: #f2f2f2;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
      animation: animloader 6s linear infinite;
    }
  }
}
