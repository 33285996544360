.oportunidades{
  .table-responsive {
    overflow-x: hidden; /* Cambia auto por hidden */
  }
  .bg-light-green {
    background-color: #d4edda; // Verde claro
  }
 .bg-light-red {
    background-color: #f8d7da; // Rojo claro
  }
}
  