.Cotizar {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  min-width: 950px;

  .cotizacion {
    width: 60%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 65% 35%;
    margin-top: 1rem;
    padding: 1rem;
    background-color: whitesmoke;
    grid-gap: 15px;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .letraChica {
    font-style: italic;
    font-size: 12px;
    margin: 0;
  }

  .letraChiquita {
    font-style: italic;
    margin-bottom: 1rem;
    font-size: 10px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 700px;
    margin: 0 auto;
    padding: 0.5rem;
  }

  .descuento {
    color: red;
  }

  .left {
    margin-top: 0.25rem;
    text-align: left;
  }

  .right {
    margin-top: 0.25rem;
    text-align: right;
  }

  .boton {
    padding: 1rem;

    .botonCotizar:disabled {
      background-color: gray;
    }

    .botonCotizar {
      font-size: 14px;
      padding: 6px 12px;
      margin-right: 1px;
      margin-bottom: 0;
      border-radius: 4px;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      color: white;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;
      background-color: rgb(227, 0, 27);
      width: 150px;
    }

    .botonPDF {
      font-size: 14px;
      padding: 6px 12px;
      margin-left: 1px;
      margin-bottom: 0;
      border-radius: 4px;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      color: white;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;
      background-color: darkgreen;
      width: 150px;
    }
  }
}
