.plazo {
  background-color: rgb(36, 36, 36);
  border-radius: 10px;
  border: .5px darkslategray solid;
  color: white;
  width: 100%;
}
.tituloplazo {
  color: white;
  font-size: 35px;
  margin-top: 1rem;
  text-align: center;
}
.subtitulo1 {
  color: white;
  display: block;
  font-size: 25px;
  margin-top: 1rem;
  text-align: center;
}
.subtitulo2 {
  color: white;
  display: block;
  margin-top: 1rem;
  text-align: center;
}
.seleccion{
  margin-top: 1rem;
  display: block;
  font-size: 20px;
}
.cb123{
  margin-right: 1rem;
}
.date{
  display: block;
  margin-top: 1rem;
}
.datePickerWrapper input {
  text-align: center;
  display: block;
  background-color: rgb(50, 50, 50);
  color: white;
  border: 0;
}
.bajada {
  font-size: small;
  margin: 1.5rem;
}
  