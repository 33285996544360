.bg-verde {
  background-color: green;
}

.bg-rojo {
  background-color: red;
}

.bg-azul {
  background-color: blue;
}

.bg-amarillo {
  background-color: yellow;
}
.ytd-row {
  background-color: black;
  color: white;
}

