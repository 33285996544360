.PeriodoPautaCalendario {
  label {
    text-align: left;
    white-space: nowrap;
  }
  .react-daterange-picker__wrapper {
    border: none;
    gap: 5px;
  }
  .react-daterange-picker__inputGroup {
    flex-grow: initial;
    font-size: 1rem;
    min-width: auto;
    padding: 0;

    & > input:last-child {
      width: 35px !important;
    }
  }
  .react-daterange-picker__clear-button {
    display: none;
  }
  .react-daterange-picker__calendar-button {
    display: none;
  }
  .react-daterange-picker__inputGroup__input {
    padding: 0;
  }
}
