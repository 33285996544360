.Facturas {
  .tabla {
    overflow-x: scroll;
    width: 100%;

    &:last-child {
      margin-top: 50px;
    }
  }
}
