.Usuarios {
  .encabezados {
    background-color: black;
    border-bottom: 1px solid #ccc;
    color: white;
    display: grid;
    grid-template-columns: 20% 20% 30% 20% 10%;
    height: 2rem;
    margin-bottom: 1rem;
    margin: auto;
    min-width: 1222px;
    text-align: center;
    width: 1400px;
  }

  .titulo {
    margin-top: 0.25rem;
  }

  .usuario-list {
    list-style: none;
    margin: 0 auto;
    margin-top: 1rem;
    min-width: 1222px;
    padding: 0;
    width: 1400px;

    .usuario-list-item {
      border-bottom: 1px solid #ccc;
      display: grid;
      grid-template-columns: 20% 20% 30% 20% 10%;
      border-right: 1px black;
      text-align: center;
    }

    .usuario-list-item-form {
      display: grid;
      grid-template-columns: 20% 20% 30% 20% 10%;
      text-align: center;
      width: 1400px;
    }
  }
}
