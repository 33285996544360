.Slider {
  position: absolute;
  left: 25%;
  top: 5%;
  height: 50vh;
  min-width: 950px;

  .right-arrow {
    position: absolute;
    top: 50%;
    left: 55vw;
    font-size: 2.5rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }

  .left-arrow {
    position: absolute;
    top: 50%;
    left: -10vw;
    font-size: 2.5rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }

  .slide {
    border-radius: 10px;
    height: 110%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform: scale(0.92);
    transition-duration: 1s ease;
    width: 50vw;

    .image {
      height: 100%;
      margin: 0 auto;
      width: 100%;
      object-fit: cover;
    }

    &.active {
      opacity: 1;
      transform: scale(1);
      transition-duration: 1s;
    }
  }
}
