.Sales {
  min-width: 950px;
  margin-top: 1rem;
  margin-bottom: 4rem;

  .encabezados {
    background-color: black;
    border-bottom: 1px solid #ccc;
    color: white;
    display: grid;
    grid-template-columns: 25% 25% 10% 10% 20% 10%;
    height: 2rem;
    margin-bottom: 1rem;
    margin: auto;
    min-width: 1222px;
    text-align: center;
    width: 1000px;
  }

  .titulo {
    margin-top: 0.25rem;
  }
}
