.Mapa {
  height: 100vh;
  position: relative;
  width: 100%;

  .logo {
    bottom: 10px;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);

    img {
      height: auto;
      width: 100px;
    }
  }
}
