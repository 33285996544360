.CalculadorComisionEdificio {
  th[rowSpan="2"] {
    vertical-align: middle;
  }

  .rango-fecha {
    label {
      text-align: left;
      white-space: nowrap;
    }
    .react-daterange-picker__wrapper {
      border: none;
      gap: 5px;
    }
    .react-daterange-picker__inputGroup {
      flex-grow: initial;
      font-size: 1rem;
      min-width: auto;
      padding: 0;

      & > input:last-child {
        width: 35px !important;
      }
    }
    .react-daterange-picker__clear-button {
      display: none;
    }
    .react-daterange-picker__calendar-button {
      display: none;
    }
    .react-daterange-picker__inputGroup__input {
      padding: 0;
    }
  }

  .table-custom {
    width: 80%; /* Ajusta la tabla al 70% del ancho total */
    margin: 0 auto; /* Centra la tabla horizontalmente */
    margin-bottom: 50px; /* Añade un margen inferior de 50px */
    border-spacing: 0; /* Elimina el espacio entre las filas por defecto */
  }

  .total-row {
    border-bottom: 10px solid white; /* Espaciado visual */
  }

  .uppercase {
    text-transform: uppercase; /* Convierte el texto a mayúsculas */
  }
}
