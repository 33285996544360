.bg-verde {
  background-color: green;
}

.bg-rojo {
  background-color: red;
}

.bg-azul {
  background-color: blue;
}

.bg-amarillo {
  background-color: yellow;
}
.recharts-legend-wrapper {
  display: none !important;
}
.active-filter {
  background-color: lightblue !important; /* Cambia a azul claro */
  border-color: #007bff !important; /* Borde azul para que combine */
}