.frecuencia {
  background-color: rgb(36, 36, 36);
  border-radius: 10px;
  border: .5px darkslategray solid;
  color: white;
}
.titulofrecuencia {
  color: white;
  font-size: 35px;
  margin-top: 1rem;
  text-align: center;
}
.subtitulo {
  color: white;
  display: block;
  font-size: 25px;
  margin-top: 1rem;
  text-align: center;
}
.checkboxfrecuencia{
  font-size: 20px; 
  margin-left: 1rem;
  align-items: center;
  align-content: center;
  position:relative;
} 
