.programmatic-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 10px; /* Ajusta el margen derecho entre los componentes */
  }

  .full-width {
    display: flex;
    justify-content: center;
    margin: 0 auto; 
    width: 80%;
  }
  
  
.d-flex {
  display: flex;
}

.d-flex > * {
  flex: 20%;
}
