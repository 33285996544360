/* ProgrammaticCalculadora.css */
.table-container {
    max-height: 400px; /* Ajusta según sea necesario */
    overflow-y: auto;
  }
  
  .table thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white; /* Cambia según tu diseño */
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid black;
    text-align: center;
    vertical-align: middle;
    padding: 10px;
  }
  
  .table-dark th {
    background-color: #343a40;
    color: white;
  }