.table-fixed th.numero-factura {
    width: 5%;
  }
  
  .table-fixed th.fecha-factura {
    width: 5%;
  }
  
  .table-fixed th.cliente {
    width: 26%;
  }
  
  .table-fixed th.intermediario {
    width: 10%;
  }
  
  .table-fixed th.edificios-participantes {
    width: 5%;
  }
  
  .table-fixed th.pantallas-involucradas {
    width: 5%;
  }
  
  .table-fixed th.importe-bruto {
    width: 12%;
  }
  
  .table-fixed th.importe-neto {
    width: 12%;
  }
  
  .table-fixed th.comision {
    width: 11%;
  }
  
  .table-fixed th.cobrada {
    width: 10%;
  }
  