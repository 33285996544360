.Home {
  align-items: center;
  background-image: url("../../img/background.png"); // Reemplaza 'ruta/de/tu/imagen.jpg' con la ruta de tu imagen
  background-size: cover; // Esto ajustará la imagen para que cubra todo el fondo
  background-position: center;   color: #fff;
  display: flex;
  font-family: "poppins-semibold", sans-serif;
  height: 100vh;
  justify-content: center;
  backdrop-filter: blur(1px); // Ajusta el nivel de desenfoque aquí


  .container-auxiliar {
    width: 95%;
    z-index: 1;

    @media (width >= 768px) {
      width: 75%;
    }

    @media (width >= 992px) {
      width: 70%;
    }
  }

  .logo {
    display: inline;
    backdrop-filter: blur(1px); // Ajusta el nivel de desenfoque aquí
    width: 80%;

    @media (width >= 768px) {
      height: 35vw;
    }

    @media (width >= 992px) {
      height: 15rem;
    }
  }

  .bajada {
    font-size: 5vw;
    font-weight: bolder;
    line-height: 1.22;
    backdrop-filter: blur(1px); // Ajusta el nivel de desenfoque aquí
    width: fit-content;

    @media (width >= 768px) {
      font-size: 7vw;
    }

    @media (width >= 992px) {
      font-size: 68px;
    }
  }

  .texto-titulo {
    padding: 0; // Eliminar padding para el título en Chile
    font-size: 2.5rem; // Ajusta el tamaño de la fuente para h1
    color: white; // Ajusta el color según tu necesidad
    text-align: left;
    width: 80%;
    font-weight: bold;
  }

  .texto {
    width: 85%;
    font-size: 1.5rem; // Ajusta el tamaño de la fuente para los párrafos
    color: white; // Ajusta el color según tu necesidad
    text-align: left;
  }

  .bajada {
    font-size: 1rem; // Ajusta el tamaño de la fuente para el texto bajada
    color: white; // Ajusta el color según tu necesidad
    text-align: left;
  }

  .correo {
    font-size: 1.5rem; // Ajusta el tamaño de la fuente para los párrafos
    font-weight:normal;
    text-align: left;
    width: fit-content;

    @media (width >= 992px) {
      margin-top: 1.5rem;
      font-size: 1.5rem;
    }
  }

}
