/* Estilo para el contenedor del dropdown */
.dropdown-container {
  width: 75%;
  margin: 0 auto;
  font-size: 1rem;
}

/* Estilo para la tabla */
.table-container {
  width: 75%;
  margin: 0 auto;
}

.table-container th:nth-child(1) {
  width: 25%;
}

.table-container th:nth-child(2) {
  width: 10%;
}

.table-container th:nth-child(3) {
  width: 65%;
}
