/* ProgrammaticCalculadora.css */
.table-container-a {
    max-height: 400px; /* Ajusta según sea necesario */
    max-width: 85%; /* Ajusta según sea necesario */
    overflow-y: auto;
    margin: auto;
  }
  
  .table thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white; /* Cambia según tu diseño */
  }